<script src="@/components/ContactsComponent/ContactsComponent.js"></script>

<template>
	<section id="contacts" class="bg-grayBlue lg:py-130 py-68">
		<div class="container xl:mb-104 lg:mb-68 mb-60">
			<p class="font-body text-gray text-18 mb-24">
						<span class="before:content-['||'] before:mr-16">
							Контакты
						</span>
			</p>
			<h2 class="font-title font-bold xl:text-52 lg:text-48 md:text-32 sm:text-28 text-24 text-lightGray">
				Оставьте заявку на разработку и продвижение сайта
			</h2>
		</div>
		<div class="container">
			<form
				class="max-w-380 w-full flex flex-wrap justify-between text-lightGray"
				@submit.prevent="submitForm"
			>
				<input
					class="bg-grayBlue border border-darkGray focus:border-lightGray focus-visible:outline-none md:w-[30%] w-full p-16  mb-16"
					v-model="form.name"
					type="text"
					placeholder="Ваше имя"
				>
				<input
					class="bg-grayBlue border border-darkGray focus:border-lightGray focus-visible:outline-none md:w-[30%] w-full p-16  mb-16"
					v-model="form.phone"
					type="tel"
					placeholder="Телефон"
				>
				<select
					class="block rounded-none bg-grayBlue border border-darkGray focus:border-lightGray focus-visible:outline-none md:w-[30%] w-full h-56 p-16 mb-16"
					v-model="form.subject"
				>
					<option
						v-for="(option,index) in select"
						:key="index"
						:value="option"
						:selected="index === 0"
					>
						{{ option }}
					</option>
				</select>
				<textarea
					class="w-full bg-grayBlue border border-darkGray focus:border-lightGray focus-visible:outline-none p-16 mb-16"
					v-model="form.message"
					rows="5"
					placeholder="Комментарий"
				>
					Комментарий
				</textarea>
				<button
					class="border border-gray hover:bg-lightGray hover:text-darkBlue transition-colors duration-500 rounded-full py-16 px-48"
					type="submit"
				>
					Отправить
				</button>
			</form>
		</div>
	</section>
</template>
