import { createApp } from "vue"
import App from "./App.vue"
import "./registerServiceWorker"
import router from "./router"
import "@/assets/css/main.css"
import VueYandexMetrika from "vue3-yandex-metrika"

createApp(App).use(router).use(VueYandexMetrika, {
	id: 95707243,
	router: router,
	env: process.env.NODE_ENV,
	options: {
		clickmap: true,
		trackLinks: true,
		accurateTrackBounce: true,
		webvisor: true
	}
}).mount("#app")
