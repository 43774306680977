export default {
	name: "HomeComponent",
	methods: {
		smoothScroll (event) {
			event.preventDefault()
			const targetId = event.target.getAttribute("href").replace("#", "")
			const element = document.getElementById(targetId)

			if (element) {
				element.scrollIntoView({ behavior: "smooth" })
			}
		}
	}
}
