<template>
	<header-component/>
<!--	eslint-disable-next-line-->
	<router-view/>
<!--	eslint-disable-next-line-->
	<footer-component/>
</template>

<script>
import FooterComponent from "@/components/FooterComponent/FooterComponent.vue"
import HeaderComponent from "@/components/HeaderComponent/HeaderComponent.vue"

export default {
	components: {
		"header-component": HeaderComponent,
		"footer-component": FooterComponent
	}
}
</script>
