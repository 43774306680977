<script src="@/views/HomeView/HomeView.js"></script>

<template>
	<div>
		<home-component />
		<service-component />
		<about-component />
		<special-component />
		<contacts-component />
	</div>
</template>
