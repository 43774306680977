import { Swiper, SwiperSlide } from "swiper/vue"
// eslint-disable-next-line no-unused-vars
import { Navigation, Pagination, Autoplay } from "swiper/modules"

export default {
	name: "ServicesComponent",
	components: {
		Swiper,
		SwiperSlide
	},
	data () {
		return {
			slides: {
				1: {
					image: '<path d="M467,0H45A45.05,45.05,0,0,0,0,45V467a45.05,45.05,0,0,0,45,45H467a45.05,45.05,0,0,0,45-45V45A45.05,45.05,0,0,0,467,0ZM30,45A15,15,0,0,1,45,30h75v90H30ZM482,467a15,15,0,0,1-15,15H45a15,15,0,0,1-15-15V150H482Zm0-347H150V30H467a15,15,0,0,1,15,15ZM437,60H407a15,15,0,0,0,0,30h30a15,15,0,0,0,0-30Zm-91,0H316a15,15,0,0,0,0,30h30a15,15,0,0,0,0-30Zm-90,0H226a15,15,0,0,0,0,30h30a15,15,0,0,0,0-30Zm30,121H75a15,15,0,0,0-15,15v60a15,15,0,0,0,15,15H286a15,15,0,0,0,15-15V196A15,15,0,0,0,286,181Zm-15,60H90V211H271Zm166-60H346a15,15,0,0,0-15,15V437a15,15,0,0,0,15,15h91a15,15,0,0,0,15-15V196A15,15,0,0,0,437,181ZM422,422H361V211h61ZM286,301H75a15,15,0,0,0-15,15V437a15,15,0,0,0,15,15H286a15,15,0,0,0,15-15V316A15,15,0,0,0,286,301ZM271,422H90V331H271Z"/>',
					title: "Веб-Сайты",
					description: "Создаем уникальные и эффективные сайты, которые рассказывают историю вашего бренда, обеспечивая максимальное вовлечение и конверсию."
				},
				2: {
					image: '<path d="M482,183.58V45A45.05,45.05,0,0,0,437,0H45A45.05,45.05,0,0,0,0,45V316a45.05,45.05,0,0,0,45,45H170.91l-15.56,31.64A75.11,75.11,0,0,0,90,467a15,15,0,0,0,15,15H273.58A45.07,45.07,0,0,0,316,512H467a45.05,45.05,0,0,0,45-45V226A45.07,45.07,0,0,0,482,183.58ZM30,45A15,15,0,0,1,45,30H437a15,15,0,0,1,15,15V60H30ZM271,452H122.57A45.08,45.08,0,0,1,165,422H271Zm0-60H189.09l15.25-31H271Zm0-61H45a15,15,0,0,1-15-15V301H271Zm0-105v45H30V90H452v91H316A45.05,45.05,0,0,0,271,226ZM482,467a15,15,0,0,1-15,15H316a15,15,0,0,1-15-15V452H482Zm0-45H301V271H482Zm0-181H301V226a15,15,0,0,1,15-15H467a15,15,0,0,1,15,15v15Z"/>',
					title: "Веб-Приложения",
					description: "Мы создаем интуитивно понятные, мощные веб-приложения, которые повышают продуктивность и открывают новые возможности для вашего бизнеса."
				},
				3: {
					image: '<path d="M421,76a15,15,0,1,1-15-15A15,15,0,0,1,421,76Zm-60,0a15,15,0,1,1-15-15A15,15,0,0,1,361,76Zm-60,0a15,15,0,1,1-15-15A15,15,0,0,1,301,76ZM498.81,435.17,482.43,418.8,482,55A55.06,55.06,0,0,0,427,0H55A55.06,55.06,0,0,0,0,55V427a55.06,55.06,0,0,0,55,55H418.35l16.82,16.82a45,45,0,0,0,63.64-63.64ZM55,30H427a25,25,0,0,1,25,25l.08,66H30V55A25,25,0,0,1,55,30Zm0,422a25,25,0,0,1-25-25V151H452.11l.29,237.76L415.6,352c24.41-68.24-26.5-140.26-98.89-140.26a105.12,105.12,0,0,0-105,105c0,72.49,72.09,123.27,140.26,98.9L388.35,452Zm261.71-60.28a75,75,0,1,1,75-75A75.09,75.09,0,0,1,316.71,391.71ZM477.59,477.6a15,15,0,0,1-21.21,0l-76.83-76.83a105.31,105.31,0,0,0,21.21-21.22l76.84,76.83a15,15,0,0,1,0,21.22ZM76,211H226a15,15,0,0,0,0-30H76a15,15,0,0,0,0,30Zm90,30H76a15,15,0,0,0,0,30h90a15,15,0,0,0,0-30Zm0,60H76a15,15,0,1,0,0,30h90a15,15,0,1,0,0-30Zm0,60H76a15,15,0,0,0,0,30h90a15,15,0,0,0,0-30Z"/>',
					title: "SEO",
					description: "Повышаем видимость вашего сайта в поисковых системах, привлекая целевую аудиторию и увеличивая органический трафик."
				},
				4: {
					image: '<path d="M467,0H45A45.05,45.05,0,0,0,0,45V286a45.05,45.05,0,0,0,45,45H91v45a15,15,0,0,0,15,15h23.18a132.68,132.68,0,0,0,5.34,12.84l-16.41,16.41a15,15,0,0,0,0,21.21l42.43,42.42a15,15,0,0,0,21.21,0l16.41-16.4A132.68,132.68,0,0,0,211,472.82V497a15,15,0,0,0,15,15h60a15,15,0,0,0,15-15V472.82a132.68,132.68,0,0,0,12.84-5.34l16.41,16.4a15,15,0,0,0,21.21,0l42.42-42.42a15,15,0,0,0,0-21.21l-16.41-16.41A134.67,134.67,0,0,0,382.83,391H406a15,15,0,0,0,15-15V331h46a45.05,45.05,0,0,0,45-45V45A45.05,45.05,0,0,0,467,0ZM391,361H371.76a15,15,0,0,0-14.52,11.24A104.36,104.36,0,0,1,346.11,399a15,15,0,0,0,2.31,18.23l13.65,13.64-21.22,21.22-13.64-13.65A15,15,0,0,0,309,436.11a104,104,0,0,1-26.74,11.13A15,15,0,0,0,271,461.76V482H241V461.76a15,15,0,0,0-11.24-14.53A103.69,103.69,0,0,1,203,436.11a15,15,0,0,0-18.23,2.31l-13.65,13.65-21.21-21.22,13.65-13.64A15,15,0,0,0,165.89,399a104.36,104.36,0,0,1-11.13-26.74A15,15,0,0,0,140.24,361H121V330.9h61.52a75,75,0,1,0,147,.1H391ZM211,346a45.09,45.09,0,0,1,2.58-15h84.84A45,45,0,1,1,211,346Zm271-60a15,15,0,0,1-15,15H45a15,15,0,0,1-15-15V45A15,15,0,0,1,45,30H467a15,15,0,0,1,15,15ZM220.26,209.86a15,15,0,0,0,16.34-3.26l60-60a15,15,0,0,0,0-21.21l-60-60A15,15,0,0,0,211,76V196A15,15,0,0,0,220.26,209.86ZM241,112.21,264.79,136,241,159.79ZM437,241H391V226a15,15,0,0,0-30,0v15H331V226a15,15,0,0,0-30,0v15H181V226a15,15,0,0,0-30,0v15H90V226a15,15,0,0,0-30,0v30a14.91,14.91,0,0,0,15,14.9H437a15,15,0,1,0,0-29.9Z"/>',
					title: "Работа с CMS",
					description: "Реализуем гибкие и масштабируемые решения на популярных CMS, облегчая управление контентом и его обновление."
				},
				5: {
					image: '<path d="M455,0H55A55.06,55.06,0,0,0,0,55V367a55.06,55.06,0,0,0,55,55H180v60H135a15,15,0,0,0,0,30H375a15,15,0,0,0,0-30H330V422H455a55.06,55.06,0,0,0,55-55V55A55.06,55.06,0,0,0,455,0ZM30,55A25,25,0,0,1,55,30H455a25,25,0,0,1,25,25V302H30ZM300,482H210V422h90ZM480,367a25,25,0,0,1-25,25H55a25,25,0,0,1-25-25V332H480ZM335,91H184.21l-4.66-18.64A15,15,0,0,0,165,61H135a15,15,0,0,0,0,30h18.29l27.16,108.64A15,15,0,0,0,195,211H315a15,15,0,0,0,14.64-11.75l20-90A15,15,0,0,0,335,91Zm-32,90H206.71l-15-60H316.3Zm-63,75a15,15,0,1,1-15-15A15,15,0,0,1,240,256Zm60,0a15,15,0,1,1-15-15A15,15,0,0,1,300,256Z"/>',
					title: "E-commerce",
					description: "Разрабатываем мощные и безопасные решения для электронной коммерции, оптимизированные для увеличения продаж и улучшения пользовательского опыта."
				},
				6: {
					image: '<path d="M467,0H45A45.05,45.05,0,0,0,0,45V345a45.05,45.05,0,0,0,45,45H175.19l-20,60H106a15,15,0,0,0,0,30H406a15,15,0,0,0,0-30H356.81l-20-60H467a45.05,45.05,0,0,0,45-45V45A45.05,45.05,0,0,0,467,0ZM186.81,450l20-60h98.38l20,60ZM482,345a15,15,0,0,1-15,15H45a15,15,0,0,1-15-15V330H482ZM163.58,213.79l-13.65-13.64,21.21-21.22,13.65,13.65A15,15,0,0,0,203,194.89a104.36,104.36,0,0,1,26.74-11.13A15,15,0,0,0,241,169.24V150h30v19.24a15,15,0,0,0,11.24,14.52A104,104,0,0,1,309,194.9a15,15,0,0,0,18.23-2.32l13.64-13.65,21.22,21.22-13.65,13.64A15,15,0,0,0,346.11,232a104.36,104.36,0,0,1,11.13,26.74A15,15,0,0,0,371.76,270H391v30H329.48a75,75,0,1,0-147,0H121V270h19.24a15,15,0,0,0,14.53-11.24A104,104,0,0,1,165.89,232a15,15,0,0,0-2.31-18.23ZM301,285a45.09,45.09,0,0,1-2.58,15H213.58A45,45,0,1,1,301,285Zm181,15H421V255a15,15,0,0,0-15-15H382.83a134.67,134.67,0,0,0-5.35-12.84l16.41-16.41a15,15,0,0,0,0-21.21l-42.42-42.43a15,15,0,0,0-21.21,0l-16.41,16.41A132.06,132.06,0,0,0,301,158.18V135a15,15,0,0,0-15-15H226a15,15,0,0,0-15,15v23.18a132.06,132.06,0,0,0-12.84,5.34l-16.41-16.41a15,15,0,0,0-21.21,0l-42.42,42.43a15,15,0,0,0,0,21.21l16.4,16.41A132.06,132.06,0,0,0,129.18,240H106a15,15,0,0,0-15,15v45H30V45A15,15,0,0,1,45,30H467a15,15,0,0,1,15,15ZM406,60a45,45,0,1,0,45,45A45.05,45.05,0,0,0,406,60Zm0,60a15,15,0,1,1,15-15A15,15,0,0,1,406,120Z"/>',
					title: "Производительность Сайтов",
					description: "Оптимизируем производительность вашего сайта для ускорения загрузки, улучшения UX и повышения общей эффективности."
				},
				7: {
					image: '<path d="M490.45,304.59,479.68,298l10.77-6.62c28.67-17.53,28.74-47.37,0-64.94l-10.77-6.58,10.77-6.62c28.75-17.57,28.65-59.26,0-76.75L279.41,6.68a44.78,44.78,0,0,0-46.92,0l0,0L21.55,136.48c-28.74,17.56-28.65,59.26,0,76.75l10.81,6.65-10.77,6.57c-28.64,17.51-28.76,47.36,0,64.91L32.32,298l-10.77,6.58C-7.2,322.16-7.16,358,21.51,375.5l211,129.83a44.83,44.83,0,0,0,46.95,0l211-129.78c28.74-17.56,28.73-53.37,0-70.93ZM37.23,162.05,248.17,32.24h0a14.91,14.91,0,0,1,15.57,0L474.81,162.07a15,15,0,0,1,0,25.62l-211,129.78a14.94,14.94,0,0,1-15.6,0l-211-129.83A15,15,0,0,1,37.23,162.05ZM30,258.92c0-.5,1.34-3.31,7.18-6.88L61,237.51,232.49,343.05a44.81,44.81,0,0,0,46.95,0L451,237.5,474.81,252c5.84,3.57,7.16,6.38,7.18,6.88s-1.34,3.31-7.22,6.9l-211,129.78a14.9,14.9,0,0,1-15.6,0L37.19,265.8C31.35,262.23,30,259.42,30,258.92Zm444.76,91-211,129.78a14.92,14.92,0,0,1-15.6,0l-211-129.83c-9.53-5.82-9.54-13.92,0-19.75L61,315.65,232.49,421.19a44.81,44.81,0,0,0,46.95,0L451,315.64l23.79,14.54c4.56,2.79,7.18,6.39,7.18,9.88s-2.62,7.08-7.22,9.89Z" transform="translate(0 -0.04)"/>',
					title: "UX/UI",
					description: "Разрабатываем дизайн, который выглядит великолепно на всех устройствах, обеспечивая идеальное отображение вашего сайта."
				},
				8: {
					image: '<path d="M467,0H45A45.05,45.05,0,0,0,0,45V467a45.05,45.05,0,0,0,45,45H467a45.05,45.05,0,0,0,45-45V45A45.05,45.05,0,0,0,467,0ZM30,45A15,15,0,0,1,45,30h75v90H30ZM482,467a15,15,0,0,1-15,15H45a15,15,0,0,1-15-15V150H482Zm0-347H150V30H467a15,15,0,0,1,15,15ZM437,60H407a15,15,0,0,0,0,30h30a15,15,0,0,0,0-30Zm-90,0H316a15,15,0,0,0,0,30h31a15,15,0,0,0,0-30Zm-91,0H226a15,15,0,0,0,0,30h30a15,15,0,0,0,0-30ZM437,422H422V227a15,15,0,0,0-15-15H347a15,15,0,0,0-15,15V422H301V287a15,15,0,0,0-15-15H226a15,15,0,0,0-15,15V422H180V347a15,15,0,0,0-15-15H105a15,15,0,0,0-15,15v75H75a15,15,0,0,0,0,30H437a15,15,0,0,0,0-30Zm-287,0H120V362h30Zm121,0H241V302h30Zm121,0H362V242h30Z"/>',
					title: "Веб-Аналитика",
					description: "Предоставляем глубокий анализ веб-аналитики, чтобы вы могли понять поведение вашей аудитории и оптимизировать ваш сайт соответственно."
				}
			},
			modules: [Navigation, Pagination, Autoplay],
			breakpoints: {
				320: { slidesPerView: 1, spaceBetween: 20 },
				544: { slidesPerView: 1.5, spaceBetween: 40 },
				768: { slidesPerView: 2, spaceBetween: 30 },
				992: { slidesPerView: 3, spaceBetween: 40 },
				1216: { slidesPerView: 3.5, spaceBetween: 40 }
			},
			pagination: {
				clickable: true
			}
		}
	},
	mounted () {
		this.updateSwiperClasses()
	},
	methods: {
		updateSwiperClasses () {
			const swiper = this.$el.querySelector(".swiper").swiper
			// Обновление классов пагинации
			swiper.wrapperEl.classList.add("flex", "items-stretch", "relative", "box-content", "w-full", "h-full", "transition-transform", "z-10")
			// Пагинация контейнер
			swiper.pagination.el.classList = "absolute text-center transition-colors z-20 w-full left-0 bottom-8"
			// Пагинация точки
			swiper.pagination.bullets.forEach(bullet => {
				bullet.classList = "bullet pointer inline-block h-8 w-8 mx-4 rounded-full"
			})
			swiper.params.pagination.bulletActiveClass = "active"
			// Обновление классов кнопок навигации
			swiper.navigation.nextEl.classList = "slider-next group pointer z-30"
			swiper.navigation.nextEl.innerHTML = '<svg class="h-full w-full text-lightGray group-hover:text-gray transition-colors duration-500"  width="32" height="32" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"/><polyline points="9 6 15 12 9 18"/></svg>'
			swiper.navigation.prevEl.classList = "slider-prev group pointer z-30"
			swiper.navigation.prevEl.innerHTML = '<svg class="h-full w-full text-lightGray group-hover:text-gray transition-colors duration-500"  width="32" height="32" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"/><polyline points="15 6 9 12 15 18"/></svg>'
		},
		smoothScroll (targetId) {
			const element = document.getElementById(targetId)
			if (element) {
				element.scrollIntoView({ behavior: "smooth" })
			}
		}
	}
}
