export default {
	name: "HeaderComponent",
	data () {
		return {
			burger: false,
			isScrolledBeyond: false,
			menuItems: [
				{
					name: "Главная",
					link: "#home"
				},
				{
					name: "Услуги",
					link: "#services"
				},
				{
					name: "O Компании",
					link: "#about"
				},
				{
					name: "Акция",
					link: "#special"
				},
				{
					name: "Связаться",
					link: "#contacts"
				}
			]
		}
	},
	methods: {
		burgerToggle: function () {
			this.burger = !this.burger
		},
		handleScroll () {
			this.isScrolledBeyond = window.scrollY > 120
		},
		smoothScroll (event) {
			event.preventDefault()
			const targetId = event.target.getAttribute("href").replace("#", "")
			const element = document.getElementById(targetId)

			if (element) {
				element.scrollIntoView({ behavior: "smooth" })
			}
		},
		dropDownLink (event) {
			this.smoothScroll(event)
			this.burgerToggle()
		}
	},
	mounted () {
		window.addEventListener("scroll", this.handleScroll)
	},
	beforeDestroy () {
		window.removeEventListener("scroll", this.handleScroll)
	}
}
