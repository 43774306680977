<script src="@/components/HomeComponent/HomeComponent.js"></script>

<template>
	<section id="home" class="relative overflow-hidden">
		<div class="absolute top-0 left-0 w-full h-full">
			<video
				loop muted autoplay preload="metadata" playsinline
				class="absolute top-0 left-0 w-full h-full object-center object-cover"
				src="@/assets/video/bg_video.mp4"
				poster="@/assets/images/bg_main.png"
			>
			</video>
			<div class="absolute bg-darkBlue95 top-0 left-0 w-full h-full"></div>
			<div class="lg:block hidden absolute -translate-x-1/2 -translate-y-1/2">
				<span class="block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border border-lightGray10 rounded-full w-440 h-440"></span>
				<span class="block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border border-lightGray10 rounded-full w-560 h-560"></span>
				<span class="block border border-lightGray10 rounded-full w-700 h-700"></span>
			</div>
			<div class=" lg:block hidden absolute bottom-0 right-0 translate-x-1/2 translate-y-1/2">
				<span class="block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border border-lightGray10 rounded-full w-440 h-440"></span>
				<span class="block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border border-lightGray10 rounded-full w-560 h-560"></span>
				<span class="block border border-lightGray10 rounded-full w-700 h-700"></span>
			</div>
		</div>
		<div class="grid lg:grid-cols-3 grid-cols-2 relative container lg:pt-180 pt-130 lg:pb-0 pb-68">
			<div class="col-span-2">
				<h1 class="font-title font-bold text-lightGray mb-40">
					<span class="block xl:text-64 lg:text-48 md:text-64 sm:text-48 text-28">Добро пожаловать <br> в студию</span>
					<span class="block xl:text-104 lg:text-72 md:text-104 sm:text-72 text-40">Lebedev Web</span>
				</h1>
				<p class="font-body xl:text-22 lg:text-20 md:text-22 sm:text-20 text-gray mb-60">
					Мы создаём не просто сайты. Мы создаём Вашу историю успеха в интернете!
				</p>
				<div class="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-30 text-center">
					<a
						class="block group border border-darkGray rounded-full hover:bg-lightGray transition-colors duration-500"
						href="#services"
					>
						<span class="block uppercase text-lightGray group-hover:text-darkBlue leading-none transition-colors duration-500 py-32 px-32">
							Перейти к услугам
						</span>
					</a>
					<a
						class="block group border border-darkGray rounded-full hover:bg-lightGray transition-colors duration-500"
						href="#contacts"
					>
						<span class="block uppercase text-lightGray group-hover:text-darkBlue leading-none transition-colors duration-500 py-32 px-32">
							Заказать сайт
						</span>
					</a>
				</div>
			</div>
			<div class="relative xl:h-[700px] lg:h-[550px] lg:block hidden">
				<img
					class="absolute top-0 left-0 w-full h-full object-contain object-top saturate-50"
					src="@/assets/images/main_programmer.png">
			</div>
		</div>
	</section>
</template>
