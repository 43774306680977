<script src="@/components/SpecialComponent/SpecialComponent.js"></script>

<template>
	<section id="special" class="bg-darkBlue lg:py-130 py-68">
		<div class="container md:mb-104 mb-68">
			<p class="font-body text-gray text-18 mb-24">
				<span class="before:content-['||'] before:mr-16">
					Специальное предложение
				</span>
			</p>
			<h2 class="font-title font-bold lg:text-52 sm:text-32 text-28 text-lightGray">
				Выгодные цены на разработку сайтов!
			</h2>
		</div>
		<swiper
			:modules="modules"
			:slidesPerView="2.1"
			:space-between="30"
			:centeredSlides="true"
			:loop="true"
			:breakpoints="breakpoints"
			:navigation="true"
			:pagination="pagination"
			class="special relative container overflow-hidden touch-pan-y z-10 h-full pb-48 mb-130"
		>
			<swiper-slide
				v-for="slide in slides"
				:key="slide"
				class="block relative group bg-darkBlue transition-transform shrink-0 h-auto items-stretch overflow-hidden"
				v-slot="{ isActive }"
			>
				<article
					class="px-32 md:py-68 py-32 bg-grayBlue transition-opacity duration-500"
					:class="isActive ? 'opacity-100' : 'opacity-40'"
				>
					<svg class="fill-lightGray w-56 h-56 mb-24 mx-auto" v-html="slide.icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"></svg>
					<h3 class="font-semibold font-title md:text-24 sm:text-24 text-20 text-center text-lightGray mb-24">{{ slide.title }}</h3>
					<div class="text-center font-bold text-red sm:text-28 text-24 mb-48">
						<p class="inline-block border border-lightGray rounded-full py-2 sm:px-48 px-32">{{ slide.price }} ₽</p>
					</div>
					<ul class="mb-40">
						<li
							v-for="item in slide.items"
							:key="item"
							class="flex justify-between items-center border-b border-b-gray py-12"
						>
							<p class="text-lightGray sm:text-16 text-14" v-html="item"></p>
							<svg class="fill-lightGray shrink-0 w-16 h-16 ml-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 520 520"><path d="M54.05,237.48c-29.93.09-54.13,25-54,55.76a56.45,56.45,0,0,0,12.16,34.93l137.68,173A48.67,48.67,0,0,0,191,519.92c16.71-.92,31.8-10.09,41.42-25.17l286-472.5.15-.24c2.68-4.22,1.81-12.6-3.73-17.86A14.92,14.92,0,0,0,493.34,5c-.21.23-.41.47-.61.72s-.21.26-.32.38L204,340.41a12.27,12.27,0,0,1-17.64,1.1l-.13-.12L90.47,252a53.19,53.19,0,0,0-36.42-14.55Z"/></svg>
						</li>
					</ul>
					<div class="text-center">
						<a
							class="inline-block uppercase text-lightGray bg-red rounded-full animate-pulse py-16 px-48"
							href="#contacts"
						>
							Заказать
						</a>
					</div>
				</article>
			</swiper-slide>
		</swiper>
	</section>
</template>
