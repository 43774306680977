export default {
	name: "ContactsComponent",
	data () {
		return {
			select: ["Выберите тему", "Специальное предложение", "Разработка сайта", "Интернет-магазин", "SEO Продвижение", "Оптимизация готового сайта"],
			form: {
				name: "",
				phone: "",
				subject: "Выберите тему",
				message: ""
			}
		}
	},
	methods: {
		submitForm () {
			fetch("https://landing.lebedev-web.ru/telegram/", {
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify(this.form)
			})
				.then(response => {
					if (response.ok) {
						this.resetForm() // Очистка формы после успешной отправки
						return response.json()
					} else {
						throw new Error("Network response was not ok.")
					}
				})
				.then(data => console.log(data))
				.catch(error => console.error("Error:", error))
		}
	}
}
