<script src="@/components/HeaderComponent/HeaderComponent.js"></script>

<template>
	<header
		class="fixed top-0 left-0 right-0 z-900"
		:class="{ 'bg-darkBlue': isScrolledBeyond }"
	>
		<div></div>
		<div class="container flex items-center justify-between py-16">
			<p class="font-title text-lightGray">Lebedev Web</p>
			<div class="flex items-center">
				<ul class="flex items-center text-lightGray hidden lg:flex">
					<li class="mr-12 last:mr-0" v-for="item in menuItems" :key="item">
						<a
							class="hover:text-red transition-colors duration-300"
							:href="item.link"
							@click="smoothScroll"
						>{{ item.name }}</a>
					</li>
				</ul>
				<div
					class="relative w-32 h-16 ml-12 lg:hidden"
					@click="burgerToggle"
				>
					<div
						class="absolute bg-lightGray h-2 right-0 top-0 origin-top-right transition-transform duration-500"
						:class="burger ? 'w-32 -rotate-[30deg]' : 'w-16'"
					></div>
					<div
						class="absolute bg-lightGray h-2 right-0 top-1/2 transition-width duration-300"
						:class="burger ? 'w-0' : 'w-32'"
					></div>
					<div
						class="absolute bg-lightGray h-2 right-0 top-full origin-bottom-right transition-transform duration-500"
						:class="burger ? 'w-32 rotate-[30deg]' : 'w-16'"
					></div>
				</div>
			</div>
		</div>
		<div
			class="bg-darkBlue transition-height duration-500 overflow-hidden lg:hidden"
			:class="burger ? 'max-h-[1000px]' : 'max-h-0'"
		>
			<ul class="container">
				<li class="text-lightGray border-b border-b-gray" v-for="item in menuItems" :key="item">
					<a
						class="block hover:text-red transition-colors duration-300 p-12"
						:href="item.link"
						@click="dropDownLink"
					>
						{{ item.name }}
					</a>
				</li>
			</ul>
		</div>
	</header>
</template>
