import HomeComponent from "@/components/HomeComponent/HomeComponent.vue"
import ServicesComponent from "@/components/ServicesComponent/ServicesComponent.vue"
import AboutComponent from "@/components/AboutComponent/AboutComponent.vue"
import SpecialComponent from "@/components/SpecialComponent/SpecialComponent.vue"
import ContactsComponent from "@/components/ContactsComponent/ContactsComponent.vue"
export default {
	name: "HomeView",
	data () {
		return {
			logo: null,
			navigation: null,
			menuButtons: {},
			burger: false
		}
	},
	methods: {
		initializeVKPixel () {
			// Check if the script is already inserted
			if (document.getElementById("tmr-code")) return

			// Initialize the VK Pixel
			window._tmr = window._tmr || []
			window._tmr.push({ id: "3424035", type: "pageView", start: (new Date()).getTime() })

			// Create script tag
			const script = document.createElement("script")
			script.type = "text/javascript"
			script.async = true
			script.id = "tmr-code"
			script.src = "https://top-fwz1.mail.ru/js/code.js"

			// Insert the script tag into the DOM
			const firstScriptTag = document.getElementsByTagName("script")[0]
			firstScriptTag.parentNode.insertBefore(script, firstScriptTag)
		}
	},
	mounted () {
		this.initializeVKPixel()
	},
	components: {
		"home-component": HomeComponent,
		"service-component": ServicesComponent,
		"about-component": AboutComponent,
		"special-component": SpecialComponent,
		"contacts-component": ContactsComponent
	}
}
