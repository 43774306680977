<script src="@/components/FooterComponent/FooterComponent.js"></script>

<template>
	<footer class="bg-darkBlue border-t border-t-gray">
		<div class="container md:flex justify-between items-center text-center md:text-start py-32">
			<p class="font-title font-bold text-lightGray text-32 md:ml-68 shrink-0">
				Lebedev Web
			</p>
			<p class="text-lightGray -order-1 md:mt-0 mt-48">
				© 2023 Копирование материалов сайта допускается только со ссылкой на источник.
			</p>
		</div>
	</footer>
</template>
