<script src="@/components/AboutComponent/AboutComponent.js"></script>

<template>
	<section id="about" class="bg-grayBlue lg:py-130 py-68">
		<div class="container grid xl:grid-cols-3 lg:grid-cols-2 gap-50 items-center">
			<div class="xl:col-span-2">
				<div class="2xl:mb-68 mb-40">
					<p class="font-body text-gray text-18 mb-24">
						<span class="before:content-['||'] before:mr-16">
							О компании
						</span>
					</p>
					<h2 class="font-title font-bold 2xl:text-52 text-32 text-lightGray">
						Наши сильные стороны
					</h2>
				</div>
				<p class="text-lightGray 2xl:text-20 text-16">
					В Lebedev Web мы сочетаем творчество, технологии и стратегическое видение для развития Вашего бизнеса в цифровом мире. Основанная в 2016 году, наша команда профессионалов постоянно исследует новейшие тренды веб-разработки, предоставляя клиентам не просто услуги, а комплексные решения для успеха их бизнеса в Интернете. Мы гордимся тем, что предлагаем персонализированные и инновационные подходы к каждому проекту.
				</p>
			</div>
			<div class="">
				<img class="drop-shadow-xl lg:w-full w-380 mx-auto" src="@/assets/images/About.png" alt="О компании">
			</div>
		</div>
	</section>
</template>
