<script src="@/components/ServicesComponent/ServicesComponent.js"></script>

<template>
	<section id="services" class="bg-grayBlue lg:pt-130 pt-68">
		<div class="container xl:mb-104 lg:mb-68 mb-60">
			<p class="font-body text-gray text-18 mb-24">
				<span class="before:content-['||'] before:mr-16">
					Услуги Студии
				</span>
			</p>
			<h2 class="font-title font-bold 2xl:text-52 xl:text-48 lg:text-40 md:text-32 sm:text-28 text-22 text-lightGray">
				Инновационные Веб-Решения для Вашего Бизнеса!
			</h2>
		</div>
		<swiper
			:modules="modules"
			:loop="true"
			:autoplay="{duration: 5000}"
			:centeredSlides="true"
			:breakpoints="breakpoints"
			:navigation="true"
			:pagination="pagination"
			class="services relative container overflow-hidden touch-pan-y z-10 h-full pb-48 lg:mb-130 mb-68"
		>
			<swiper-slide v-for="slide in slides" :key="slide" class="block relative group bg-darkBlue transition-transform shrink-0 h-auto items-stretch overflow-hidden">
				<article class="px-32 lg:py-68 py-32">
					<div class="absolute top-0 left-0"></div>
					<svg class="w-56 h-56 mb-60 fill-lightGray" v-html="slide.image" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"></svg>
					<h3 class="font-semibold text-24 text-lightGray mb-48">{{ slide.title }}</h3>
					<p class="text-16 text-gray">{{ slide.description }}</p>
				</article>
				<div class="absolute top-0 right-0 translate-x-full -translate-y-full opacity-0 group-hover:translate-x-2/4 group-hover:-translate-y-2/4 group-hover:opacity-100 transition-transform duration-500">
					<span class="block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border border-lightGray10 rounded-full w-300 h-300"></span>
					<span class="block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border border-lightGray10 rounded-full w-380 h-380"></span>
					<span class="block border border-lightGray10 rounded-full w-440 h-440"></span>
				</div>
				<div class="absolute bottom-0 right-0 translate-x-full translate-y-full opacity-0 group-hover:translate-x-2/4 group-hover:translate-y-2/3 group-hover:opacity-100 transition-transform duration-500">
					<span class="block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border border-lightGray10 rounded-full w-300 h-300"></span>
					<span class="block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border border-lightGray10 rounded-full w-380 h-380"></span>
					<span class="block border border-lightGray10 rounded-full w-440 h-440"></span>
				</div>
			</swiper-slide>
		</swiper>
		<div class="bg-darkBlue py-48">
			<div class="container grid xl:grid-cols-4 md:grid-cols-3 gap-30 items-center justify-center">
				<p class="text-lightGray xl:col-span-3 md:col-span-2 md:text-start text-center md:text-22 sm:text-20 text-18">
					Не отставайте от конкурентов -<br>сделайте шаг навстречу цифровому будущему уже сегодня!
				</p>
				<a
					class="animate-pulse block border group rounded-full overflow-hidden text-center"
					href="#contacts"
					@click="smoothScroll"
				>
					<span class="block py-16 px-32 text-darkBlue bg-lightGray">
						Оставьте заявку
					</span>
				</a>
			</div>
		</div>
	</section>
</template>
